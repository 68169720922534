import calendarServices from '../../services/calendarServices';
import Moment from 'moment';
import 'moment-timezone';

interface CalendarEvent {
  calendarId: string;
  speaker: string;
  attendee: string;
  sendUpdates: string;
  start: {
    dateTime: string;
    timeZone: string;
  };
  end: {
    dateTime: string;
    timeZone: string;
  };
  summary: string;
  location: string;
  creator: string;
  selite: string;
  q: string;
}

const updateSpeakerCalendarNew = async (
  token: string,
  filters: any,
  speaker: string,
  startdate: string,
  starttime: string,
  enddate: string,
  endtime: string,
  subject: string,
  selite: string,
  prevspeaker: string,
  location: string,
  attendee: string,
  serv: any,
  deleteonly: string
) => {
  let puhujaCalendarId = '';

  const removeEventFromCalendar = async (id: string, event: CalendarEvent) => {
    try {
      await calendarServices.removeEventGen(id, event, token);
    } catch (error) {
      console.error('Error removing event:', error);
    }
  };

  const handleCalendarEvent = async (event: CalendarEvent) => {
    try {
      const events = await calendarServices.getCalendarByDate(event, token);

      if (Array.isArray(events)) {
        events.forEach((existingEvent: any) => {
          removeEventFromCalendar(existingEvent.id, event);
        });
      } else {
        console.warn('Unexpected response:', events);
      }

      if (deleteonly === 'deletespeaker' && puhujaCalendarId !== event.calendarId) {
        await calendarServices.insertEventGen(event, token);
      }

      if (deleteonly !== 'deleteonly' && deleteonly !== 'deletespeaker') {
        await calendarServices.insertEventGen(event, token);
      }
    } catch (error) {
      console.error('Error handling calendar event:', error);
    }
  };

  const buildEventObject = (
    calendarId: string,
    summary: string,
    attendeeEmail: string,
    startDateTime: string,
    endDateTime: string,
    prevSpeaker: string
  ): CalendarEvent => ({
    calendarId,
    speaker,
    attendee: attendeeEmail,
    sendUpdates: process.env.REACT_APP_MAIL === 'testi' ? 'none' : 'all',
    start: { dateTime: Moment.tz(startDateTime, 'Europe/Helsinki').format(), timeZone: 'Europe/Helsinki' },
    end: { dateTime: Moment.tz(endDateTime, 'Europe/Helsinki').format(), timeZone: 'Europe/Helsinki' },
    summary,
    location,
    creator: 'seurat@helsinginrauhanyhdistys.fi',
    selite,
    q: prevSpeaker,
  });

  const startDateTime = `${Moment(startdate, 'D-M-YYYY').format('YYYY-MM-DD')}T${Moment(starttime, 'HH:mm').format(
    'HH:mm'
  )}`;
  const endDateTime = `${Moment(enddate, 'D-M-YYYY').format('YYYY-MM-DD')}T${Moment(endtime, 'HH:mm').format('HH:mm')}`;

  const locations = serv.type.trim() ? ['Puhujat', serv.Location || ' '] : [serv.Location || ' '];

  locations.forEach((locationName) => {
    let summary = '';
    let prevSpeaker = '';

    if (locationName === 'Puhujat') {
      summary = `${subject} ${speaker}`;
      prevSpeaker = prevspeaker;
    } else {
      summary =
        locationName === 'Keittiö' || serv.Location === 'Keittiö'
          ? `${serv.Toimikunta} / ${subject} / ${serv.Selite}`
          : subject;
      attendee = 'matti.friman@helsinginrauhanyhdistys.fi';
      prevSpeaker = serv.subject;
    }

    const calendarEntry = filters.calendarDataArray.find((entry: any) => entry.calendar === locationName) || {};
    const calendarId = calendarEntry.calendarId || '';

    if (locationName === 'Puhujat') {
      puhujaCalendarId = calendarId;
    }

    if (locationName.trim()) {
      const event = buildEventObject(calendarId, summary, attendee, startDateTime, endDateTime, prevSpeaker);
      handleCalendarEvent(event);
    }
  });
};

export default updateSpeakerCalendarNew;
