import programService from "../../services/programService";
import { editFilterData } from "../../actions/filters";

export const GenGetAllSubSheetsToFire = async (dispatch: any, sheetId: any, filters: any, token: any, auth: any ) => {     
  let initialLines: any = []
  const fileId = process.env.REACT_APP_SHEET_ID;
  const initialRows = await programService
    .getAllSubSheetsToFire(fileId, sheetId, token, filters.serviceYear, auth.uid, auth.email );
  if (Array.isArray(initialRows)) {
    initialLines = initialRows;
  }
  else {
    initialLines = [];
    //dispatch(editFilterData({error: true, errorMessage: 'GenGetAllLines to firebase failed, check sheetId params '}));
  };
  return initialLines;
}
