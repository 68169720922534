
/* eslint-disable linebreak-style */
/* eslint-disable semi */

/* eslint-disable linebreak-style */

/* eslint-disable linebreak-style */
// eslint-disable-next-line linebreak-style
/* eslint-disable quotes */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import axios from 'axios'
// eslint-disable-next-line semi
//import regeneratorRuntime from "regenerator-runtime";
// eslint-disable-next-line quotes
// eslint-disable-next-line semi

import "regenerator-runtime/runtime.js";

const baseUrl                = `${process.env.REACT_APP_API_URL}/api/hryspeakersfb`;
const getSubsheetsToFire     = `${process.env.REACT_APP_API_URL}/api/getallsubsheettofire`;
const UrlAllSubSheets        = `${process.env.REACT_APP_API_URL}/api/getallsubsheets`;
const createyearsheet        = `${process.env.REACT_APP_API_URL}/api/createyeartable`;
const getSheetUrl            = `${process.env.REACT_APP_API_URL}/api/getsheetfb`;
const baseUrlPatch           = `${process.env.REACT_APP_API_URL}/api/updatespeakerfb`;
const baseUrlPatchColorfb    = `${process.env.REACT_APP_API_URL}/api/batchupdatefb`;
const batchInsertLine         = `${process.env.REACT_APP_API_URL}/api/batchInsertLine`;
const batchUpdateLine         = `${process.env.REACT_APP_API_URL}/api/batchUpdateLine`;
const baseUrlSpSheetPatch    = `${process.env.REACT_APP_API_URL}/api/updatespeakersheetfb`;
const authUrl                = `${process.env.REACT_APP_API_URL}/api/hryauthority`;
const createSheetUrl         =  `${process.env.REACT_APP_API_URL}/api/createdrivesheet`;
const createSheetToGsheetUrl =  `${process.env.REACT_APP_API_URL}/api/updateSheetsToGSheet`;
const addDataToSheetUrl      =  `${process.env.REACT_APP_API_URL}/api/addDataToSheet`;
const baseUrlSpSheetLeaves   = `${process.env.REACT_APP_API_URL}/api/updatespeakerleavesspf`;
const baseUrlSpSheetLeaveTypes = `${process.env.REACT_APP_API_URL}/api/updatespeakerleavetypesspf`;
const firebaseurl = `${process.env.REACT_APP_API_URL}/api/getfirebase`;

const getAuth = async (token: any, sheetId: any, rangeId: any) => {
  try{
     const request =  await axios.get(authUrl,{params:{sheetId:sheetId, range:rangeId},headers: { Authorization: token }})
     return request.data;
  }catch(error) {
    const response =  `Error in getAuth ${sheetId} ${rangeId}`;
    return response;
  }
}
const getFirebaseData = async(token:any, year: any, sheetid: any, uid: any, email: any ) => {
  const config = { headers: {Authorization : token }};
  try{
    const request = await axios.get(`${firebaseurl}/${sheetid}/${year}/${uid}/${email}`, config  );
    return request.data

  }
  catch(e) {
    console.log('firebase etrieve failed', e);
    return 'Error in getFirebaseData'
  }
}
const getAllSubSheetsToFire = async(fileId: any, sheetId: any, token:any, year: any, uid: any, email: any ) => {
  const config = { headers: {Authorization : token }};
  try{
    const request = await axios.get(`${getSubsheetsToFire}/${fileId}/${year}/${sheetId}/${uid}/${email}`, config  );
    return request.data

  }
  catch(e) {
    console.log('firebase etrieve failed', e);
    return 'Error in getFirebaseData'
  }

}
const getSheet = async (token: any, sheetId: any, range: any) => {
  const config = {
    headers: { Authorization: token },
  }

  try {
    const request = await axios.get(`${getSheetUrl}/${sheetId}/${range}`,config);
      return request.data
    }catch(errors) {
      console.log('errrrrrors',errors)
      const response =  `Error in getSheet ${sheetId} ${range}`;
      return response;
   }
  }
const getAll = async (token: any,servYear: any, sheetid: any ,range: any ) => {
  try {
    const request = await axios.get(`${baseUrl}/${servYear}`,{params:{sheetId:sheetid, range:range},headers: { Authorization: token }})
    return request.data
  }catch(error: any) { 
    if (error.response.status === 490) {
      window.alert('iD´dToken on ennyt vanhaksi, paina selaimen refresh-painiketta')
    }
    console.log('Parametristovirhe ', error)
    const response =  `Parametristosta virheitä  ${sheetid} ${range} ${error} `;
   
    return response;
  }

}
const getAllSubSheets = async (token: any, param: any ) => {
  const  config = {
    headers: { Authorization: token }
  }

  try {
    const request = await axios.post(`${UrlAllSubSheets}`,{param}, config)
    return request.data
  }catch(error: any) {
    console.log('Parametristovirhe ', error, error.message, error.response.status, error.response,)
    const response =  `Parametristossa virheitä ${error.response.data.error} `;
    return response;
  }
}

const createdrivesheet = async (token: any,text: any) => {
  const config = {
    headers: { Authorization: token }
  }
  const sheetResponse = axios.post(`${createSheetUrl}/${text}`,{token} ,config)
  return sheetResponse.then(response => response.data)
}

const updateSheetsToGSheet = async (token: any,sheetId: any,drive: any, dispatch: any) => {
  const config = {
    headers: { Authorization: token }
  }
  const sheetToGsheetResponse = axios.post(`${createSheetToGsheetUrl}/${sheetId}` ,{ drive } ,config)
  return sheetToGsheetResponse.then(response => response.data)
}
const updateDataToSheets = async (token: any,sheetId: any,driveName: any, columnValues: any, rowValues: any, w_sheetId: any) => {
  const sheetData = ({
    driveName : driveName,
    columnValues:columnValues,
    rowValues:rowValues,
    subSheet : w_sheetId
  })
  const config = {
    headers: { Authorization: token }
  }
  const updateDataToSheetResponse = axios.post(`${addDataToSheetUrl}/${sheetId}` ,sheetData ,config)
  return updateDataToSheetResponse.then(response => response.data)
}

const update = async (token: any, id: any, servYear: any, sheetId: any, range: any, subSheet: any, newValue: any, columnStart: any, columnEnd: any, email: any) => {
  const config = {
    headers: { Authorization: token },
  };
  const request = axios.post(`${baseUrlPatch}/${id}`, { servYear,sheetId, range, subSheet, newValue, columnStart, columnEnd, email }, config )
  return request.then(response => response.data)
}
const updateColor = async (token: any, id: any, sheetId: any, subSheet: any, strikeThrough: any,columnStart: any, columnEnd: any) => {
  const config = {
    headers: { Authorization: token },
  };
  try {
     const request = await axios.post(`${baseUrlPatchColorfb}/${id}`, { sheetId, subSheet, strikeThrough, columnStart, columnEnd }, config );
     return request.data

  }catch(error) {
    console.log('error happened in updateColor/ProgramService');
    return 'Error';
  }; 
}

const insertNewLine = async (token: any, id: any, subSheetId: any, startLine: any, endLine: any, line: any, range: any, uid: any, serviceYear: any)  => {
  const config = {
    headers: { Authorization: token },
  };
  try{
    const request = await axios.post(`${batchInsertLine}/${id}`, { subSheetId, startLine, endLine, line, range, uid, serviceYear }, config )
    return request.data
  }catch(error) {
    const response =  `Error in insert ${id} ${range}`;
    return response;
  }
}
const updateLine = async (token: any, id: any, subSheetId: any,  startLine: any, endLine: any, line: any, range: any) => {
  const config = {
    headers: { Authorization: token },
  };
  try{
    const request = await axios.post(`${batchUpdateLine}/${id}`, { subSheetId, startLine, endLine, line, range }, config )
    return request.data
  }catch(error) {
    const response =  `Error in update ${id} ${range}`;
    return response;
  }
}
const updatespsheet =  async(token: any, speaker: any, id: any, newObject: any,st1: any, st2: any, st3: any, st4: any, st5: any, st6: any, st7: any, st8: any, st9: any, st0: any, st10: any, st11: any, 
  serviceYear: any, subSheet: any, spreadsheetId: any, speakerSubColumn: any, speakerSubDayCol1: any, speakerSubDayCol2: any) => {
  const config = {
    headers: { Authorization: token },
  }
  const request =  axios.post(`${baseUrlSpSheetPatch}/${id}`, { speaker, newObject,st1,st2,st3,st4,st5,st6,st7,st8,st9,st0,st10, st11, serviceYear,subSheet,spreadsheetId, speakerSubColumn, speakerSubDayCol1, speakerSubDayCol2}, config )
  return request.then(response => response.data)
}

// const createYearTable =  (token: any, speaker: any, id: any, newObject: any,st1: any,st2: any,st3: any,st4: any,st5: any,st6: any,st7: any,st8: any,st9: any,st0: any,st10: any, st11: any, 
//   serviceYear: any, subSheet1: any,spreadsheetId1: any, speakerSubColumn: any, speakerSubDayCol1: any, speakerSubDayCol2: any,datesArrayFinal: any) => {
//   const config = {
//     headers: { Authorization: token },
//   }
//   const request =  axios.post(`${createyearsheet}/${id}`, { speaker, newObject,st1,st2,st3,st4,st5,st6,st7,st8,st9,st0,st10, st11, 
//     serviceYear,subSheet1,spreadsheetId1, speakerSubColumn, speakerSubDayCol1, speakerSubDayCol2, datesArrayFinal}, config )
//   return request.then(response => response.data)
// }

const updatespleaves =  async(token: any, id: any, leavetimes: any,servYear: any, sheetId: any, columnAddInfo: any, subSheet: any) => {
  const config = {
    headers: { Authorization: token },
  };

  const request =  axios.post(`${baseUrlSpSheetLeaves}/${id}`, { leavetimes,servYear,sheetId, columnAddInfo, subSheet }, config )
  return request.then(response => response.data)
}
const updatespleaveTypes =  async(token: any, id: any, types: any,servYear: any, sheetId: any, columnAddInfo: any, subSheet: any) => {
  const config = {
    headers: { Authorization: token },
  }
  //const request = axios.patch(`${baseUrlSpSheetPatch}/${id}`,config, servtimes)

  const request =  axios.post(`${baseUrlSpSheetLeaveTypes}/${id}`, { types,servYear,sheetId, columnAddInfo, subSheet }, config )
  return request.then(response => response.data)
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  updateDataToSheets :updateDataToSheets,
  // createYearTable: createYearTable,
  updateSheetsToGSheet : updateSheetsToGSheet,
  createdrivesheet: createdrivesheet,
  getAll: getAll,
  getAuth: getAuth,
  insertNewLine: insertNewLine,
  updateLine: updateLine,
  update: update ,
  updateColor: updateColor,
  updatespsheet: updatespsheet,
  getSheet:getSheet,
  updatespleaves:updatespleaves,
  updatespleaveTypes:updatespleaveTypes,
  getAllSubSheets:getAllSubSheets,
  getFirebaseData: getFirebaseData,
  getAllSubSheetsToFire: getAllSubSheetsToFire
}