
import { editFilterData } from '../../actions/filters';
import database from '../../firebase/firebase';

const readSheetDataFromFire = async (dispatch: any, filters: any, services: any, auth: any, serviceYear: any) => {

  const readFirebaseDatabase = async (fbParam: string) => {
    try{
      let snapshot: any = [];
      const data = await database.ref(`users/${auth.uid}/serviceYear/${filters.serviceYear}/${fbParam}${filters.serviceYear}`).once('value');
      await data.forEach((childSnapshot: any) => {
        snapshot.push({
          typeId: childSnapshot.key,
          ...childSnapshot.val()
        });
      });
      const dataArr = [...snapshot];
      let lineArray: any = [];
      for (let i = 1; i < 99; i++) {
        if(dataArr[0][`Line${i}`]){
          lineArray.push(dataArr[0][`Line${i}`]);
        } else {
          i=99
        }             
      }
      const newArray = lineArray.map((line: any) => {
        return line.split(',')
      });
      return newArray;  
    } 
    catch (error) {
      console.error('Error fetching data:', error);
    };
  };

  const getSubjects = async () => {
    const allSubjects = services.reduce ((results: any, data: any) => { 
      const result = results;
      result[data.subject] = result[data.subject] || []
      result[data.subject].push ({
        sspeaker : data.sspeaker    
      });
      return result;
    },{});
    return allSubjects;
  }

  const getAllSubSheetsP = async (year: any ) => {
    const paramTable = ['types','TMK-nimet', 'location', 'seuraIsannat', 'saestajat', 'esilaulajat', 'valvojat','aanentoisto','tulkit'];
    let arrayTable: any = [];
    for (const param of paramTable) {
      const newArray: any[]  = await readFirebaseDatabase(param);
      arrayTable.push(newArray);
      }
    return arrayTable;
    }
    const fetchData = async () => {
      try {
      
        const subjects = await getSubjects();
        const sortedSubject = Object.keys(subjects).sort();
       
        const parameters: any = await getAllSubSheetsP(filters.serviceYear);
        dispatch(editFilterData({
          parameters: {
            typesValues: parameters[0],
            TMKValues: parameters[1],
            locationValues: parameters[2], 
            seuraIsannat: parameters[3], 
            saestajat: parameters[4], 
            esilaulajat: parameters[5],
            valvojat: parameters[6], 
            aanitarkkailijat: parameters[7], 
            tulkit: parameters[8], 
            subjectValues: sortedSubject,
          },
        }));
        return parameters
      } catch (error) {
        console.error('Error fetching data:', error);
      };        
    };

  return fetchData();
      
}
export default readSheetDataFromFire;
  