import React, { useState, useEffect}  from 'react';
import { Box, CircularProgress } from '@mui/material';
import { setLoading } from '../../actions/filters';
import { useDispatch, useSelector } from 'react-redux';
import {RootState} from '../../store/ConfigureStore';
import { useNavigate } from "react-router-dom";
import { ReadServiceAndSpeakersToRedux } from '../genUtilities/ReadServiceAndSpeakersToRedux';
import { Navigate } from 'react-router-dom';
import { GenGetAllSubSheetsToFire } from '../genUtilities/GenGetAllSubSheetsToFire';

const InfoPage = () => {
  const dispatch = useDispatch();
  const authorit: any = useSelector((state: RootState) => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters);
  const services: any = useSelector((state: RootState) => state.services);
  const token = `bearer ${authorit.idToken}`;
  const [errorMessage, setErrorMessage] = useState (false);
  let componentMounted = true;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [buttonStyle, setButtonStyle] = useState<"inherit" | "primary" | "secondary" | "default" | undefined>('primary');

  let navigate = useNavigate();
  useEffect (() => {
    // if (authorit.idToken) {
      if (filters.error) {
        setErrorMessage(filters.errorMessage);
      }

    if (!authorit.idToken) navigate("/", {replace:true}); 
    
    const getSheets = async() => {
      let returned: any = ''; 
      try {
          returned = await ReadServiceAndSpeakersToRedux (dispatch, token, filters.serviceYear, filters, authorit);
      } catch(error) {
        console.log('Erroro reading services and speakers', error);
        dispatch({error: true, errorMessage: 'something wron when reading services and speakers  in infoPage-routine'});
      }
      try {
        GenGetAllSubSheetsToFire( dispatch, returned.currentSheet.sheetId, filters.serviceYear, token, authorit);
      }catch(error) {
        console.log('Erroro reading subsheets', error);
        dispatch({error: true, errorMessage: 'something wron when reading subSheets to firebase  in infoPage-routine'});
      };
      dispatch(setLoading(false));
    } 
    dispatch(setLoading(true))
    getSheets();

   
   
    if (componentMounted) {
      dispatch(setLoading(false))
    }

    return () => { // This code runs when component is unmounted
      // eslint-disable-next-line react-hooks/exhaustive-deps
      componentMounted = false; // (4) set it to false if we leave the page
    }
  },[filters.somethchanged])

  if (!authorit.idToken) return <div>Kirjaudu järjestelmään</div>
  //if (loading) <CircularProgress/>
  if (filters.loading) return <div> Waiting for services<CircularProgress/></div> 

  return  (
    <Box>
      {services.length > 0 &&
        <Navigate to="/seuraohjelma" replace={true} />
      }       
     </Box>
  )
}
export default InfoPage