
import moment from 'moment';

let speakerArray: any[] = [];

const createSpeakersState = async (initialSpeakers: any) => {
  speakerArray = [];
  
  initialSpeakers.forEach((sp: any, index: number) => {
    // Alusta puuttuvat arvot oletusarvoilla
    for (let i = 3; i <= 19; i++) {
      sp[i] = sp[i] || ' ';
    }

    // Muunna päivämäärät
    const datesArray = sp[4].split(',').map((da: string) => {
      if (da.indexOf('-') === -1 && sp[4] !== '') {
        const date = moment(da, 'D.M.YYYY');
        return {
          apvm: date,
          ppvm: date,
          duration: 0,
        };
      } else if (sp[4] === '') {
        return {
          apvm: moment('31.12.2040', 'DD.MM.YYYY'),
          ppvm: moment('31.12.2040', 'DD.MM.YYYY'),
          duration: 0,
        };
      } else {
        const [apvm, ppvm] = da.split('-');
        return {
          apvm: moment(apvm, 'D.M.YYYY'),
          ppvm: moment(ppvm, 'D.M.YYYY'),
          duration: moment(ppvm, 'D.M.YYYY').diff(moment(apvm, 'D.M.YYYY'), 'days'),
        };
      }
    });

    // Luo objekti
    speakerArray.push({
      speaker: sp[0],
      mail: sp[2],
      phone: sp[1],
      types: sp[3],
      typesArray: sp[3] === ' ' ? [] : sp[3].split(','),
      dates: sp[4],
      datesArray: sp[4] === ' ' ? ['1.12.2020'] : sp[4].split(','),
      datesArrayFinal: datesArray,
      random: sp[5],
      linenumber: index + 1,
      servDatesx: sp[6] === ' ' ? ['1.12.2020'] : sp[6],
      servDates: sp[6].split(','),
      ...Object.fromEntries(
        Array.from({ length: 12 }, (_, i) => [
          `servDate${i + 1}x`,
          sp[8 + i] === ' ' ? ['1.12.2020'] : sp[8 + i].split(','),
        ])
      ),
      ...Object.fromEntries(
        Array.from({ length: 12 }, (_, i) => [
          `servDate${i + 1}Length`,
          sp[8 + i] === ' ' ? 0 : sp[8 + i].split(',').length,
        ])
      ),
      subjectArray: [],
      speakersTypesArray: [],
      sortSDate: '',
    });
  });

  return speakerArray;
};

export default createSpeakersState;
