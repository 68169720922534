/* eslint-disable linebreak-style */
/* eslint-disable semi */
import submitGetSheet from '../service/SubmitGetSheet';

//const dispatch = useDispatch()
const submitGetCalendarIdArr: any = async (token: any) =>{
  let calendarDataArray: any = [];

  const sheetsRetr = async (token: any, sheetId: any, range: any) => {
    const sheetsData = await submitGetSheet(token, sheetId, range);
    sheetsData.sheets[0].data[0].rowData.forEach((row: any) => {
    if (row.values[0].formattedValue){
      calendarDataArray.push({
        calendar: row.values[0].formattedValue,
        calendarId: row.values[1].formattedValue,
        update:false
      })
    }
   })
  }

  //const sheetId = '1Uj0G34cd6dgxQb6fetP56uFrJ_H0nShpwlZoDZbBmY8';
  const sheetId = process.env.REACT_APP_CALE_ID;
  const range   = 'Taulukko1!A1:I';
  await sheetsRetr(token, sheetId, range);

  return calendarDataArray;
}
export default(submitGetCalendarIdArr)