/* eslint-disable no-unused-vars */
/* eslint-disable semi */
// eslint-disable-next-line no-unused-vars
import React, {useState, useEffect }  from 'react';
//import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import  submitReadHryCals  from './submitReadHryCals';
import { v4 as uuidv4 } from 'uuid';
import {setCalendarIds, editFilterData  } from '../../actions/filters';
import CalendarListFilters from './CalendarListFilters';
// import ReadServiceProgram from '../service/ReadServiceProgram';

import { Button, CircularProgress, Checkbox, IconButton, Tooltip, Typography, Box, Table, TableHead, TableCell, TableBody, TableRow } from '@mui/material';
import moment from 'moment';
import {RootState} from '../../store/ConfigureStore';
import { subHeaderStyles } from '../../styles/themeStylesSx';
import { colors } from '../../styles/themeStylesSx';
import { setLoading } from '../../actions/filters'
import selectServices from '../../selectors/services';
import caleLines from '../../selectors/caleLines';
import MouseOverPopOver from '../../utilities/MouseOverPopover';
import submitGenCalUpd from './submitGenCalUpd';
import SidePanel from '../sidepanel/SidePanel';
import AddSingleLine from '../service/AddSingleLine';
import EditIcon from '@mui/icons-material/Edit';
import { Capitalize } from '../genUtilities/Capitalize';
import { ReadServiceAndSpeakersToRedux } from '../genUtilities/ReadServiceAndSpeakersToRedux';
import { useNavigate } from "react-router-dom";

const Text2 = 'Lukee tilakalenterit ja merkitsee rivit, joita ei seuraohjelmassa';
const Text3 = 'Lukee seuraohjelman ja merkitsee rivit, jotka puuttuvat tilakalentereista';
// const currentYear = moment().year();

let variantx:  "text" | "outlined" | "contained" | undefined = 'outlined';
let varianty:  "text" | "outlined" | "contained" | undefined = 'outlined';
const CheckCals =  () => {

 
  const auth: any = useSelector((state: RootState) => state.auth);
  const filters: any = useSelector((state: RootState) => state.filters);
  const services: any = useSelector((state: RootState) => state.services);
  const dispatch = useDispatch();
  const [resultsState, setResultsState] = useState<any>([]);
  const [listCalendar,setListcalendar] =useState<any>();
  const [listServices,setListServices] = useState(false);
  const [sortedCal,setSortedCalendar] = useState<any>([]);
  const [sortedService, setSortedService] = useState([]);
  const [state, setState] = useState<any>([false]);
  const [stateAll, setStateAll] = useState(false);
  const [lineAdded, setLineAdded] = useState(false);
  const [lineInd, setLineInd] = useState<number>(0);
  const [loading, toggleLoading] = useState(false);
  let array: any = [];

  const [anchorE1, setAnchorE1] = React.useState<HTMLElement | null>(null);
  let navigate = useNavigate();
  const [anchorE2, setAnchorE2] = React.useState<HTMLElement | null>(null);
  const [anchorE4, setAnchorE4] = React.useState<HTMLElement | null>(null);
  const [addLine, setAddLine] = useState(false);
  const token = `bearer ${auth.idToken }`;
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorE1(event.currentTarget);
  };
  const handlePopoverOpen1 = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorE2(event.currentTarget);
  };

  const handlePopoverOpen3 = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorE4(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorE1(null);
  };
  
  const handlePopoverClose1 = () => {
    setAnchorE2(null);
  };

  const handlePopoverClose3 = () => {
    setAnchorE4(null);
  };

  const open = Boolean(anchorE1);
 

  useEffect(() => {
    if (services.length === 0) {
      navigate("/seuraohjelma");
    }
    if (auth.idToken) {   
      setListServices(false);

      const readServ = async () => {
        const token = `bearer ${auth.idToken}`;  
        const returned = await ReadServiceAndSpeakersToRedux (dispatch, token, filters.serviceYear, filters, auth);
        // GenGetAllSubSheetsToFire( dispatch, returned.currentSheet.sheetId, filters, token, auth);
        const results: any = await submitReadHryCals (auth.uid, dispatch, setCalendarIds, token, filters, auth);        
        setResultsState(results);        
        //dispatch(startAddUserName());
      }

      const callcall= async() => {       
        if(lineAdded) {
          setLineAdded(false)
          let work = [...sortedCal];
          work[lineInd].isInProgram = lineInd;         
          setSortedCalendar(work);      
        }; 
        await readServ();
        dispatch( setLoading(false));   
        toggleLoading(false);    
      }    
      toggleLoading(true);
      dispatch(editFilterData({loading : true, text: ''}));     
      callcall();
    }

  },[filters.serviceYear, auth.idToken, lineAdded ])


  if (!auth.idToken) return <div> You must login</div>
  if (filters.loading)  return <div><CircularProgress /> <br />Loading Calendar data. Be patient</div>
  if (loading)  return <div><CircularProgress /> <br />Loading Service Data data. Be patient</div>
  if (!filters.admin && !filters.listCal) return (<div> <br />. Ei vaadittuja oikeuksia CheckCAls</div>);

  const ListMissingEvents = async (e: any) => {
    e.preventDefault();
    setListServices(true)
    setListcalendar(false);
    varianty = 'contained'
    variantx = 'outlined'
    const calenders: any = resultsState.Hrytilat?.concat(resultsState.Autotalli,
      resultsState.Kahvio,resultsState.Keittiö,resultsState.Yläkerrankokoushuone,
      resultsState.Kerhohuone,resultsState.Arkisto ,resultsState.Leivintupa, resultsState.Sali, resultsState.Sauna, resultsState.Toimisto, resultsState.Juhlapyhät);    
    const servicesx = await selectServices(services,filters);
    array = servicesx.map((cal: any) =>  false);
    setState(array); 
    let calendarElement: any = '';
    const serviceListing: any = servicesx.filter((serv1: any) =>  serv1.Esilaulaja !== 'Esilaulaja' &&
    !['Juhlapyhä', '', ' '].includes(serv1.Location?.trim() || '') &&
    serv1.StartTime !== '00.00' &&
    serv1.strike !== 'y' &&
    serv1.type !== '5')
      .map((serv: any) => {
        const workServStart = moment(serv.StartDate,'D.M.YYYY');
        const workServTime = moment(serv.StartTime,'HH.mm');
        const workServEnd = moment(serv.EndDate,'D.M.YYYY');
        const workServEndTime = moment(serv.EndTime,'HH.mm');
        const name = serv.sspeaker;
        calendarElement = filters.calendarDataArray.find((cal: any) => cal.calendar === serv.Location);
        let foundFromCalendar: any = '';
        if (!calendarElement) {
          foundFromCalendar = false;
        }
        else {
          foundFromCalendar = calenders.findIndex ((cal: any) => {         
            const calWorkStart = cal.start.dateTime ? moment(cal.start.dateTime,'YYYY-MM-DDTHH:mm:ssZ').format('D.M.YYYY') : moment(cal.start.date,'YYYY-MM-DD').format('D.M.YYYY');
            const calWorkTime = cal.start.dateTime ? moment(cal.start.dateTime,'YYYY-MM-DDTHH:mm:ssZ').format('HH.mm') : moment(cal.start.date,'YYYY-MM-DD').format('HH.mm');
            if (!calendarElement) return false;
            return ( moment(calWorkStart,'D.M.YYYY').unix()  === moment(workServStart,'D.M.YYYY').unix()
            && calendarElement.calendarId === cal.organizer.email && moment(workServTime,'HH.mm').format('HH.mm') === moment(calWorkTime,'HH.mm').format('HH.mm') && serv.strike === 'n' )
          })
        }
        const list =  ({
          lineId: uuidv4(),
          sortField : moment(workServStart,'D.M.YYYY').unix(),
          StartDate:  moment(workServStart,'D.M.YYYY').format('D.M.YYYY'),
          StartTime:  moment(workServTime,'HH.mm').format('HH.mm'),
          EndDate:  moment(workServEnd,'D.M.YYYY').format('D.M.YYYY'),
          EndTime:  moment(workServEndTime,'HH.mm').format('HH.mm'),
          dayOfWeek:  serv.Vkonpäivä,
          subject:    serv.subject,
          Location:   serv.Location,
          creator:    name,
          strike:     serv.strike,
          Selite: serv.Selite,
          Toimikunta: serv.Toimikunta,
          calendarIds: calendarElement ? calendarElement : '',
          isInCalendar: foundFromCalendar
        })
        return list
      })

    const sortedService: any = serviceListing.sort(( a: any,b: any ) => {
      var x: any= a.sortField
      var y: any = b.sortField
      if (x < y) {return -1}
      if (x > y) {return 1}
      else return 0
    })
    .filter((x: any) => x);
    setSortedService(sortedService)
  }

  const AddCalendarRows = async () => {
    dispatch(editFilterData({loading: true}));
    array = [...state];
    let toBeAdded: any = [];
    toBeAdded =  sortedService.filter((member: any, ind: any) => {
      if (array[ind]) {
        handleClick(ind);
        return sortedService[ind]
      }
    });
    let work1: any=[]
    for (const li of toBeAdded) {
      await submitGenCalUpd( li.calendarIds, token, li, 'NO', dispatch, editFilterData);
      const work = [...sortedService];
      work1 = work.map((ser: any) => {
        if ( ser.lineId === li.lineId ) {
          ser.isInCalendar = 99
          return  ser
        }
        else return ser
      });      
    }
    toBeAdded = [];
    setSortedCalendar(work1)
    dispatch(editFilterData({loading: false, somethchanged: Math.random()}));
  }

  const ListCalendars = async () => {
    setListcalendar(true);
    setListServices(false);
    variantx = 'contained'
    varianty = 'outlined'
    const calenders: any = resultsState.Hrytilat?.concat(resultsState.Autotalli,
      resultsState.Kahvio,resultsState.Keittiö,resultsState.Yläkerrankokoushuone,
      resultsState.Kerhohuone,resultsState.Arkisto ,resultsState.Leivintupa, resultsState.Sali, resultsState.Sauna, resultsState.Toimisto, resultsState.Juhlapyhät);
    const servicesx = [...services];

    // eslint-disable-next-line array-callback-return
    const caleList: any = calenders.map((cal: any) => {
      if (cal) {
        const workStart = cal.start.dateTime ? moment(cal.start.dateTime,'YYYY-MM-DDTHH:mm:ssZ').format('D.M.YYYY') : moment(cal.start.date,'YYYY-MM-DD').format('D.M.YYYY')
        const workTime = cal.start.dateTime ? moment(cal.start.dateTime,'YYYY-MM-DDTHH:mm:ssZ').format('HH.mm') : moment(cal.start.date,'YYYY-MM-DD').format('HH.mm')
        const i=Array.from(cal.creator.email).indexOf('@')
        let name=cal.creator.email.slice(0,i);
        // eslint-disable-next-line array-callback-return
        const caleId = filters.calendarDataArray.find((calId: any) => calId.calendarId === cal.organizer.email )
        if (!caleId) console.log('error in checkcal, not found calid foem filters calendarDataArray')
        const foundFromProgram = servicesx.findIndex ((prog: any) => {
          if (prog.Esilaulaja !=='Esilaulaja' && caleId) {
            return (moment(prog.StartDate,'D.M.YYYY').unix() === moment(workStart,'D.M.YYYY').unix()
           && moment(workTime,'HH.mm').format('HH.mm') === moment(prog.StartTime,'HH.mm').format('HH.mm') && prog.strike === 'n' )
          }
        } );
        const list =  ({
          sortField : cal.start.dateTime ? moment(cal.start.dateTime).unix() : moment(cal.start.date).unix(),
          startDate:  cal.start.dateTime ? moment(cal.start.dateTime,'YYYY-MM-DDTHH:mm:ssZ').format('D.M.YYYY') : moment(cal.start.date,'YYYY-MM-DD').format('D.M.YYYY'),
          startTime:   cal.start.dateTime ? moment(cal.start.dateTime,'YYYY-MM-DDTHH:mm:ssZ').format('HH.mm') : moment(cal.start.date,'YYYY-MM-DD').format('HH.mm'),
          endDate:     cal.end.dateTime ? moment(cal.end.dateTime,'YYYY-MM-DDTHH:mm:ssZ').format('D.M.YYYY') : moment(cal.end.date,'YYYY-MM-DD').format('D.M.YYYY'),
          endTime :    cal.end.dateTime ? moment(cal.end.dateTime,'YYYY-MM-DDTHH:mm:ssZ').format('HH.mm') : moment(cal.end.date,'YYYY-MM-DD').format('HH.mm'),
          dayOfWeek:  cal.start.dateTime ? moment(cal.start.dateTime,'YYYY-MM-DDTHH:mm:ssZ').format('dd') : moment(cal.start.date,'YYYY-MM-DD').format('dd'),
          summary:    cal.summary,
          Location:   caleId.calendar,
          creator:    name,
          isInProgram: foundFromProgram
        });
        return list
      }
    })

    const sortedCalendar: any = caleList.sort(( a: any,b: any ) => {
      var x: any= a.sortField
      var y: any = b.sortField
      if (x < y) {return -1}
      if (x > y) {return 1}
      else return 0
    })
      .filter((x: any) => x)
      const selectedRows = await caleLines(sortedCalendar, filters);
    setSortedCalendar(selectedRows)
  }
  // if(lineAdded) {
  //   setLineAdded(false)
  //   let work = [...sortedCal];
  //   work[lineInd].isInProgram = lineInd;
   
  //   setSortedCalendar(work);

  // }


  const handleClick = (i: number) => {
    array = [...state];
    let truefalse = array[i];
    truefalse = !truefalse;
    
    array[i]= truefalse;
    setState(array)
  }
  const handleClickCal = (i: number, e: any) => {
    e.preventDefault()

    dispatch(editFilterData( {newLineStartDate: sortedCal[i].startDate,
      newLineStartTime: sortedCal[i].startTime,
      newLineSubject: sortedCal[i].summary,
      newLineLocation: sortedCal[i].Location,
      newLineEndDate: sortedCal[i].endDate,
      newLineEndTime: sortedCal[i].endTime, 
      newLineVko:    moment(sortedCal[i].startDate, "D.M.YYYY").isoWeek(), 
      newLineVkonpäivä :  moment(sortedCal[i].startDate, 'D.M.YYYY').format('dd'),
    }));
    setLineInd(i);
    setAddLine(true);
  }
  const handleClickAll = () => {
    array = [...state];
    if(stateAll === false) {
       setStateAll(true);
       array = sortedService.map((cal: any) =>  true);
       setState(array); 
    } else{  
       array = sortedService.map((cal: any) =>  false); 
       setStateAll(false)
    }
    setState(array);   
  }
  return (
    <Box>
      <Box>
        <CalendarListFilters/>
      </Box>
      <Box>
        <SidePanel
        open={ addLine === true }
        close={ () => setAddLine(false)}
        >
          <Box>
            {addLine &&  
              <AddSingleLine filters = {filters} close ={ () => {setAddLine(false)}} listing= {() =>{ setLineAdded(true)}} listCale = {()=> setListcalendar(true)} />
            }
          </Box>
        </SidePanel>
      </Box>

      <Box>
        <Box>
          { filters.admin ?
          
            <Box>      
              { filters.loading ? <div> <CircularProgress/><br />...loadingxxcheckcalsissa</div>
                :
                <Box>                
                  <Button sx= {{p: 2 }  } variant={variantx} color="primary"
                    onClick = {(e) => { ListCalendars()}}
                    aria-owns = { open ? 'mouse-over-popover' : undefined}
                    aria-haspopup = "true"
                    onMouseEnter={handlePopoverOpen1}
                    onMouseLeave={handlePopoverClose1}
                    >Tilakalenterit/Seuraohjelma
                    <MouseOverPopOver popOverText = { 'Listaa tilakalentereiden tapahtumat annetulta väliltä ja tutkii, löytyvätkö vastaavat rivit seuraohjelmasta' } anchor = { anchorE2 }/>
                  </Button>
                  <Button sx= {{p: 2, m:2} } variant={varianty} color="primary"                   
                    aria-owns = { open ? 'mouse-over-popover' : undefined}
                    aria-haspopup = "true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}                     
                    onClick = {(e) => { ListMissingEvents(e)}}
                    >Seuraohjelma/Tilakalenterit
                  </Button>
                    <MouseOverPopOver popOverText = { 'Listaa seuraohjelman tilalliset rivit annetulta väliltä ja tutkii, löytyvätkö vastaavat tapahtumat tilakalentereista' } anchor = { anchorE1 }/>
                  {listCalendar &&  <Typography sx={ subHeaderStyles }>{Text2}</Typography>}
                  {listServices &&  <Typography sx={ subHeaderStyles }>{Text3}</Typography>}
                </Box>
              }
            </Box>
            :
            <div>
            </div>
            }
          {
            filters.listCal && !filters.admin ?
              <Button variant="contained" color="primary"
                onClick = {(e) => { ListCalendars()}}>Listaa kalenterit</Button>
              :
              <div>
              </div>
          }



          {listCalendar ?
            <Table>
              <TableHead>
                <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
                  <Tooltip title="Lisää rivi seuraohjelmaan" arrow>
                    <TableCell>{'x'}</TableCell>
                  </Tooltip>
                  <TableCell>{'Pvm'}</TableCell>
                  <TableCell>{'Aika'}</TableCell>
                  <TableCell>{'Pv'}</TableCell>
                  <TableCell>{'Tilaisuus'}</TableCell>
                  <TableCell>{'Tila'}</TableCell>
                  <TableCell>{'Tekijä'}</TableCell>
                  <TableCell>{ 'Seuraohjelmassa' }</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                sortedCal.map((line: any,i: any) =>{
                  const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                  return (
                    <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${i}_row`}>  
                      
                      <TableCell padding="checkbox"                       
                      >  
                      { line.isInProgram < 0 ?
                        <IconButton onClick={(e) =>handleClickCal(i,e)}><EditIcon/></IconButton>
                        :
                        ' '
                      }                           
                      </TableCell>
                      <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.startDate } </TableCell>
                      <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.startTime}  </TableCell>
                      {line.dayOfWeek !=='Su' ?
                        <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ Capitalize(line.dayOfWeek)}  </TableCell>
                        :
                        <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, color: colors.red}}>{ Capitalize(line.dayOfWeek)}  </TableCell>
                       }
                      <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.summary }  </TableCell>
                      <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.Location}  </TableCell>
                      <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.creator }  </TableCell>
                      {line.isInProgram > -1 ?
                      <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ 'Yes' }  </TableCell>
                      :
                      <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ 'No'}  </TableCell>
                      }
                  </TableRow>
                  )
                })
                }
              </TableBody>
            </Table>
            :
            <div>
            </div>
          }
           {listServices ?
            <Box>
              <Table>
                <TableHead>
                  <TableRow sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}}>
                    <TableCell
                      aria-owns = { open ? 'mouse-over-popover' : undefined}
                      aria-haspopup = "true"
                      onMouseEnter={handlePopoverOpen3}
                      onMouseLeave={handlePopoverClose3}
                    >
                      <Checkbox
                        onClick={event => handleClickAll()}
                        className="selectCheckbox"
                        checked={stateAll}
                      />
                    
                    </TableCell>
                      
                    <MouseOverPopOver popOverText = { 'Valitse /Valitse rivi-> Lisää tapahtuman Tilakalenteriin' } anchor = { anchorE4 }/>
                    <TableCell>{'Kal?'}</TableCell>
                    <TableCell>{'Apvm'}</TableCell>
                    <TableCell>{'Klo'}</TableCell>
                    <TableCell>{'Ppvm'}</TableCell>
                    <TableCell>{'Klo'}</TableCell>
                    <TableCell>{'Pv'}</TableCell>
                    <TableCell>{'Tilaisuus'}</TableCell>
                    <TableCell>{'Tila'}</TableCell>
          
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedService.map((line: any,i: any) =>{
                    const backgroundColor = i % 2 ? colors.backgroundGreen : 'transparent';
                    return (
                      <TableRow sx={{height: 20, "& td": { border: 0 }, color: 'secondary.main'}} key={`${i}_row`}                    
                      >                      
                        <TableCell padding="checkbox"                       
                        >  
                        { line.isInCalendar < 0 &&
                          <Checkbox
                            onClick={event => handleClick(i)}
                            className="selectCheckbox"
                            checked={state[i]} 
                          />
                        }
                           
                        </TableCell>
                        {line.isInCalendar > -1 && line.Location && line.Location.trim() !== '' ?
                        <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, maxWidth: '5Px'}}>{ 'Yes' }  </TableCell>
                        :
                        <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, maxWidth: '5Px'}}>{ 'No'}  </TableCell>
                        }
                        <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.StartDate } </TableCell>
                        <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.StartTime}  </TableCell>
                        <TableCell sx={{ backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.EndDate } </TableCell>
                        <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.EndTime}  </TableCell>
                        {line.dayOfWeek !=='Su' ?
                          <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.dayOfWeek}  </TableCell>
                          :
                          <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, color: colors.red}}>{ line.dayOfWeek}  </TableCell>
                        }
                        <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, maxWidth: '5Px'}}>{ line.subject }  </TableCell>
                        <TableCell sx={{backgroundColor, borderTopLeftRadius: 15, borderBottomLeftRadius: 15}}>{ line.Location}  </TableCell>
                       
                    </TableRow>
                    )

                  })
                 }
                </TableBody>
              </Table>
              <Tooltip title="Lisää valitut tapahtumat tilakalentereihin" arrow>
              <Button sx= {{p: 2 }  } variant={variantx} color="primary"
                onClick = {(e) => { AddCalendarRows()}}
                >Päivitä tilakalenterit
              </Button>
              </Tooltip>
            </Box>
            :
            <div>
            </div>
          }
        </Box>
      </Box>
    </Box>
  )
}
export default CheckCals ;